<template>
  <div class="banner-box" :style="{ width: props.width, height: props.height }">
    <n-carousel draggable autoplay>
      <n-carousel-item v-for="(item, index) in banners" :key="index" @click="handleClick(item)">
        <img class="carousel-img" :src="item.imageUrl || ''" />
      </n-carousel-item>
    </n-carousel>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
const config = useRuntimeConfig();
const props = defineProps({
  // 轮播图宽度
  width: {
    type: String,
    default: "100%",
  },
  // 轮播图高度
  height: {
    type: String,
    default: "474px",
  },
  //   页号
  page: {
    type: Number,
    default: 1,
  },
  //   页大小
  limit: {
    type: Number,
    default: 10,
  },
  //   所属类别
  where: {
    type: String,
    default: "pc-home-banner",
  },
});

// 获取banner数据列表
const { data: banners } = await useAsyncData(async () => {
  let res = await axios({
    method: "POST",
    url: `${config.public.VITE_API_HOST_MALL}/api/Advert/GetAdvertList`,
    data: {
      page: props.page,
      limit: props.limit,
      where: props.where,
    },
  });
  return res.data.data;
});
// console.log("banners", banners.value);

// 点击跳转
function handleClick(item: any) {
  console.log("item", item);
  if (item.type != 6) {
    //非内容
    switch (item.type) {
      // 网址URL
      case 1:
        window.open(item.valData, "_blank");
        break;
      //商品
      case 2:
        //跳转商品详情
        window.open(`${config.public.VITE_MALL}/goodsDetail?id=${item.valData}`, "_blank");
        break;
    }
  } else {
    //内容
    switch (item.appType) {
      //视频
      case 5:
        window.open(`${config.public.VITE_WEBSIT}/videoDetail?id=${item.valData}`, "_blank");
        break;
      //图文
      case 6:
        window.open(`${config.public.VITE_WEBSIT}/imgTextDetail?id=${item.valData}`, "_blank");
        break;
      //整屋
      case 9:
        window.open(`${config.public.VITE_WEBSIT}/caseDetail?id=${item.valData}`, "_blank");
        break;
    }
  }
  //
}
</script>
<style lang="less" scoped>
.banner-box {
  width: 100%;
  height: 474px;

  .n-carousel {
    border-radius: 12px !important;
  }

  .carousel-img {
    width: 100%;
    height: 100%;
    @apply object-cover cursor-pointer;
  }

  :deep(.n-carousel__dot) {
    width: 44px !important;
    height: 6px !important;
    opacity: 0.5;
    background: #000000 !important;
    border-radius: 100px !important;
  }

  :deep(.n-carousel__dot--active) {
    opacity: 1;
  }
}
</style>
